<template>
    <div v-if="isReadyState">
        <div class="row">
            <div v-for="widget in widgetData" :key="widget.text" class="col-lg-3 col-md-6">
                <WidgetChart :number="widget.number" :text="widget.text" :chart-color="widget.chartColor" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <Projections :orderData="orderData"/>
            </div>
        </div>
    </div>
</template>
<script>

import WidgetChart from '@/components/widgets/Widget-chart.vue'
import Projections from '@/components/widgets/Projections.vue'

export default {
    components: {
        WidgetChart,
        Projections
    },
    data() {
        return {
            isReadyState:false,
            widgetData: [],
            orderData: {}
        };
    },
    methods: {
        parseOrdersData(result){
            let arrayObj = [
                { number: result.users.buyer , text: "Buyers", chartColor: "#0b0b79"},
                { number: result.orders.total , text: "Orders", chartColor: "#3bafda"},
                { number: result.reviews , text: "Reviews", chartColor: "#f672a7"},
                { number: '$'+result.revenue , text: "Revenue", chartColor: "#6c757d"}
              ]
            this.widgetData = arrayObj;
            this.orderData = result.orders;
        },
    },
    created() {
        this.$http.get(`/analytics`)
        .then((response) => {
            if(response.data.success){
            this.parseOrdersData(response.data.data);
            this.isReadyState = true
            }
        })
    }
};
</script>
